.CopyableText {
    border: 1px solid #000;
    border-radius: 20px;
    display: inline-block;
    padding: 10px;
    position: relative;
    vertical-align: middle;
}

.CopyableText-copied {
    box-sizing: border-box;
    background-color: #321c94;
    border: 1px solid #999;
    border-radius: 20px;
    color: #fff;
    font-size: 0.8em;
    left: 50%;
    opacity: 0;
    padding: 5px;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 250ms linear;
}

.CopyableText-copied.visible {
    opacity: 1;
}