.CopyIcon {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    position: relative;
    vertical-align: middle;
    width: 20px;
}

.CopyIcon:before {
    background-color: #ccc;
    border: 2px solid #333;
    box-sizing: border-box;
    display: inline-block;
    content: ' ';
    height: 15px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 15px;
}

.CopyIcon:after {
    background-color: #fff;
    border: 2px solid #000;
    box-sizing: border-box;
    display: inline-block;
    content: ' ';
    height: 15px;
    left: 5px;
    overflow: hidden;
    position: absolute;
    top: 5px;
    width: 15px;
}