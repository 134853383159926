.ListOption {
    border-radius: 20px;
    color: #fff;
    list-style-type: none;
    margin: 0;
    padding: 2px 15px 2px 10px;
    white-space: nowrap;
}
.ListOption, .ListOption a {
    color: #fff;
}
.ListOption:before {
    content: "• ";
}
.ListOption:hover {
    background-color: #06d4bd;
    background: linear-gradient(90deg, rgba(6, 212, 189, .5) 0, rgba(7, 118,234, .5) 100%);
}