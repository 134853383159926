.ProfileVoiceButton {
    margin-left: 26px;
}

.ProfileVoiceButton-play {
    background-color: #fa5a2a;
    background: linear-gradient(90deg, #fa5a2a 0, #fec536 100%);
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    width: 40px;
    position: absolute;
    top: -1px;
    left: -25px;
}

.ProfileVoiceButton-play:after {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    border-left: 10px solid #fff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: none;
    transform: translate(-42%, -50%);
}

.ProfileVoiceButton-play:before {
}