.pages-Waves {
    position: fixed;
    bottom: -5px;
    left: 0;
    width: 100vw;
    z-index: -1;
    text-align: center;
    opacity: 0.25;
}

.opaque.pages-Waves {
    opacity: 1;
}

.pages-Waves img {
    width: 1200px;
}