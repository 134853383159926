.player-ShuffleButton {
    color: #fff;
    cursor: pointer;
    display: inline-block;
}

.player-ShuffleButton:hover {
    color: #f00;
}

/** Theme **/

.Theme-Dark .player-ShuffleButton {
    color: #fff;
}

.Theme-Dark .player-ShuffleButton:hover {
    color: #f00;
}

.Theme-Light .player-ShuffleButton {
    color: #57ffa1;
}

.Theme-Light .player-ShuffleButton:hover {
    color: #f00;
}
