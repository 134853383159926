.player-AlbumCover {
    background-color: #000;
    background-size: contain;
    color: #fff;
    display: inline-block;
    position: relative;
    padding: 30px 10px;
    box-sizing: border-box;
    text-align: center;
}

.player-AlbumCover-like {
    position: absolute;
    right: 6%;
    top: 6%;
}
