.Footer {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background 1s;
}

.Footer:hover {
    background-color: rgba(0, 0, 0, 1);
}

.Footer-social {
    display: flex;
    gap: 10px;
    padding: 10px 10px 10px 30px;
}

@media only screen and (max-width: 1200px) {
    .Footer {
        position: static;
        left: unset;
        right: unset;
        bottom: unset;
        top: unset;
    }
    .Footer:hover {
        background: none;
    }
    .Footer-social {
        justify-content: center;
    }
}

@media only screen and (max-width: 800px) {
    .Footer-social {
        padding: 10px;
    }
}