.player-LoopButton {
    cursor: pointer;
    display: inline-block;
}

/** Theme **/

.Theme-Dark .player-LoopButton {
    color: #fff;
}

.Theme-Dark .player-LoopButton:hover {
    color: #f00;
}

.Theme-Light .player-LoopButton {
    color: #57ffa1;
}

.Theme-Light .player-LoopButton:hover {
    color: #f00;
}
