.pages-MainNavigation {
    display: inline-block;
    font-size: 0.90rem;
    margin: 0;
    padding: 0;
    list-style: none;
}

.pages-MainNavigation > li {
    display: inline-block;
    margin: 0;
    padding: 0 20px;
    position: relative;
}

.pages-MainNavigation-dropdown {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
    left: 0;
    min-width: 100%;
    padding: 10px;
    position: absolute;
    text-align: left;
    /*background-color: #fff;*/
}

@media only screen and (max-width: 1200px) {
    .pages-MainNavigation {
        display: block;
    }
    .pages-MainNavigation > li {
        display: block;
    }
    .pages-MainNavigation-dropdown {
        position: static;
    }
}