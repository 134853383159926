.song-list-Row {
    align-items: center;
    color: #fff;
    display: flex;
    margin-bottom: 20px;
}

.song-list-Row-info {
    cursor: pointer;
    flex-grow: 1;
}

.song-list-Row-likes {
    white-space: nowrap;
    margin-right: 14px;
}

.song-list-Row-actions {
    color: #fff;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.song-list-Row-actions li {
    align-self: center;
    margin-right: 14px;
}

.song-List-Row-actions li:last-child {
    margin-right: 0;
}

.song-list-Row-actions li.play-button {
    opacity: 0.5;
}

.song-list-Row li.play-button:hover, .song-list-Row.playing li.play-button {
    opacity: 1;
}

.song-list-Row-name {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.3rem;
    font-weight: bold;
}

.song-list-Row.playing .song-list-Row-name {
    /*border-left: 3px solid #fff;*/
    /*padding-left: 5px;*/
    color: #fff;
}

.song-list-Row-button {
    font-size: 12px;
    line-height: 12px;
    vertical-align: middle;
}

.song-list-Row-button img {
    vertical-align: middle;
    margin-right: 5px;
    height: 18px;
}
.song-list-Row-button .label {
    vertical-align: middle;
}

.song-list-Row.playing .song-list-Row-name {
    font-weight: bold;
}