.player-FastForwardButton {
    cursor: pointer;
    display: inline-block;
    padding: 5px;
}

/** Theme **/

.Theme-Dark .player-FastForwardButton {
    color: #fff;
}

.Theme-Dark .player-FastForwardButton:hover {
    color: #c00;
}

.Theme-Light .player-FastForwardButton {
    color: #57ffa1;
}

.Theme-Light .player-FastForwardButton:hover {
    color: #f00;
}
