.demo-upload-Step {}
.demo-upload-Step h1 {
    font-size: 16px;
    margin: 0 0 20px;
}

.demo-upload-Step main {
    border: 1px solid #fcc;
    border-radius: 10px;
    margin: 10px 0;
    padding: 30px;
}

.demo-upload-Step main .FormRow:first-child {
    margin-top: 0;
}

.demo-upload-Step main .FormRow:last-child {
    margin-bottom: 0;
}

.demo-upload-Step main.center {
    text-align: center;
}

.demo-upload-Step .Button {
    margin-top: 10px;
}
.demo-upload-StepUpload-progress {
    background-color: #000;
}