.About {
    position: relative;
}

.About-graphic {
    margin-left: auto;
}

.About-microphone {
    color: #ff0;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
}

.About-microphone .microphone {
    max-width: 100%;
}

.About-microphone blockquote {
    line-height: 0.9em;
    position: absolute;
    right: 14%;
    top: 50%;
}

.About-microphone blockquote img {
    position: relative;
    top: -5px;
}

.About-microphone blockquote img:first-child {
    margin-right: 5px;
}

.About-microphone blockquote img:last-child {
    margin-left: 5px;
}

.About-choose-voice {
    display: flex;
    gap: 10px;
    font-weight: bold;
    margin-top: 30px;
}

.About-choose-voice label {
    text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
    .About-graphic {
    }
    .About-microphone blockquote {
        display: none;
    }
}