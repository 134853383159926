.page-Search main {
    display: flex;
}
.page-Search header {
    margin: 60px 20px 100px;
    align-items: end;
}
.page-Search-songnav {
    flex-shrink: 1;
}

.page-Search-songnav:first-child {
    margin-right: 30px;
}
.page-Search-songnav:last-child {
    margin-left: 30px;
}

.page-Search-demos {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
}

.page-Search-player {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.page-Search-player-stop {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .page-Search {
        margin: 20px 30px;
    }
    .page-Search header {
        margin: 20px 0;
    }
    .page-Search main {
        flex-wrap: wrap;
    }

    .page-Search.playing main .page-Search-demos {
        display: none;
    }

    .page-Search-demos {
        width: 85%;
    }
    .page-Search.playing .page-Search-songnav {
        display: none;
    }
    .page-Search main .page-Search-player {
        display: none;
    }
    .page-Search.playing main .page-Search-player {
        display: block;
    }
    .page-Search.playing .page-Search-player-stop {
        display: block;
        margin-bottom: 5px;
    }
}