.Hamburger {
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    padding: 5px;
    position: relative;
    width: 30px;
}

.Hamburger:after {
    background-color: #fff;
    box-sizing: border-box;
    content: ' ';
    height: 4px;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 4px;
}