.player-RewindButton {
    cursor: pointer;
    display: inline-block;
    padding: 5px;
}

/** Theme **/

.Theme-Dark .player-RewindButton {
    color: #fff;
}

.Theme-Dark .player-RewindButton:hover {
    color: #c00;
}

.Theme-Light .player-RewindButton {
    color: #57ffa1;
}

.Theme-Light .player-RewindButton:hover {
    color: #c00;
}
