.pages-Rings {
    /*background-color: rgba(255, 255, 255, 0.5);*/
    /*background: url('../../../public/assets/img/background-rings.svg');*/
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
}

/*.pages-Rings-img {*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    z-index: -1;*/
/*}*/
/*.pages-Rings-div {*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    background-image: url('../../../public/assets/img/bg.png');*/
/*    !*background-size: 100vw 100vh;*!*/
/*    background-repeat: no-repeat;*/
/*    background-size: contain;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    z-index: -1;*/
/*}*/

.pages-Rings-ring1, .pages-Rings-ring2, .pages-Rings-ring3, .pages-Rings-ring4, .pages-Rings-ring5, .pages-Rings-ring6 {
    position: absolute;
    left: 50%;
    /*transform: translateX(-50%);*/
}

.pages-Rings-ring1 {
    top: 100px;
    transform: translateX(-100px);
}

.pages-Rings-ring2 {
    top: -200px;
    opacity: 0.8;
    transform: translateX(-400px);
}

.pages-Rings-ring3 {
    top: -500px;
    opacity: 0.6;
    transform: translateX(-700px);
}

.pages-Rings-ring4 {
    top: -800px;
    opacity: 0.4;
    transform: translateX(-1000px);
}

.pages-Rings-ring5 {
    top: -1100px;
    opacity: 0.2;
    transform: translateX(-1300px);
}