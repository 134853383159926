.pages-Header {
    display: flex;
    margin-top: 20px;
    user-select: none;
}

.pages-Header-left {
}

.pages-Header-right {
    flex-grow: 1;
    padding-top: 24px;
    text-align: right;
}

.pages-Header-menu-toggle {
    display: none;
}

.pages-Header-nav {
}

.pages-Header-nav-options {
    display: block;
}

@media only screen and (max-width: 1200px) {
    .pages-Header {
        display: block;
    }

    .pages-Header-nav, .pages-Header, .pages-Header-right {
        text-align: center;
    }

    .pages-Header-menu-toggle {
        display: block;
    }

    .pages-Header-nav-options {
        display: none;
    }

    .pages-Header-nav-options.visible-mobile {
        display: block;
    }
}