.ListOptions {
    margin: 0;
    padding: 0;
    text-align: left;
}

@media only screen and (max-width: 1024px) {
    .ListOptions {
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 10px;
        padding: 10px;
    }
}