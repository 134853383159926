.TextInput {
    background-color: #0c0;
    background: linear-gradient(90deg, var(--gradient-color-1) 0%, var(--gradient-color-3) 100%);
    border-radius: 20px;
    display: inline-block;
    padding: 1px;
}

.TextInput input {
    background-color: #09084c;
    border: none;
    border-radius: 20px;
    color: #fff;
    /*font-size: 1.1rem;*/
    /*display: inline-block;*/
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
}

.TextInput.invalid input {
    background-color: #fcc;
    outline: 1px solid #c00;
}