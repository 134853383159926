.player-PlayButton {
    border: 2px solid #cdcfd0;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    width: 64px;
    height: 64px;
    position: relative;
}
/* cdcfd0 */
.player-PlayButton img {
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.player-PlayButton.playing img {
    transform: translate(-40%, -50%);
}

/** Theme **/

.Theme-Dark .player-PlayButton {
    color: #fff;
}

.Theme-Dark .player-PlayButton:hover {
    color: #c00;
}

.Theme-Light .player-PlayButton {
    color: #57ffa1;
}

.Theme-Light .player-PlayButton:hover {
    color: #c00;
}
