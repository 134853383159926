/*.hero-container {*/
/*    margin-top: 70px;*/
/*}*/

/*.video-hero {*/
/*    position: relative;*/
/*    width: auto;*/
/*    min-width: 100%;*/
/*    height: auto;*/
/*    min-height: 100%;*/
/*    background: transparent !*url('960x360.png') *!no-repeat;*/
/*    background-size: cover;*/
/*}*/

/*@media only screen and (max-width: 1024px) {*/
/*    .hero-container {*/
/*        margin-top: 0px;*/
/*    }*/
/*    .hero-container.row {*/
/*        display: block;*/
/*        text-align: center;*/
/*    }*/
/*}*/
.home-services {
    margin: 50px 0;
}
.home-services h2 {
    font-size: 2rem;
    text-align: center;
}

.home-service-list {
    display: flex;
    gap: 50px;
    justify-content: center;
}