.pages-NavButtons, .pages-NavButton {
    display: inline-block;
}

.pages-NavButton {
    margin-right: 15px;
}

.pages-NavButton:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 1200px) {
    .pages-NavButton {
        margin-right: 0;
    }
    .pages-NavButtons, .pages-NavButton {
        display: block;
        margin: 10px;
    }
}