.SelectVoiceButton {
    align-items: center;
}
.SelectVoiceButton-img {
    margin-right: 5px;
}

.SelectVoiceButton-img.spin {
    animation: SelectVoiceSpin linear 600ms;
}

@keyframes SelectVoiceSpin {
    50% { transform: rotate(360deg); }
    75% { transform: scale(1.2); }
    100% { transform: scale(1); }
}