.account-home {
    gap: 20px;
    display: flex;
}

.account-home-profile {
    width: 200px;
    padding-top: 70px;
}

.account-home-divider {
    /*
    69FF6B
    789EFF 57%
    A64AFF 98%
     */
    /*background-color: #30c;*/
    background: linear-gradient(90deg, var(--gradient-color-1) 0%, var(--gradient-color-2) 57%, var(--gradient-color-3) 100%);
    width: 3px;
}

.account-home-voices {
    padding-top: 70px;
}

.account-home-voices header h1 {
    margin-top: 0;
}

.account-home-voices main {
    display: flex;
}

.account-home-demos {
}

.account-home-player {
    width: 250px;
}

.account-home-photo-container {
    border-radius: 100%;
    overflow: hidden;
    max-width: 150px;
    margin: 0 auto;
}

@keyframes load-pulse {
    0% {
        background-color: rgba(50, 0, 150, 0.5);
    }
    50% {
        background-color: rgba(50, 0, 150, 1);
    }
}

.profile-loading .account-home-photo {
    background-color: rgba(50, 0, 150, 0.5);
    animation: linear load-pulse 2s infinite;
}

.account-home-photo {
    background-color: rgba(50, 0, 150, 0.5);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    outline: 1px dashed #c00;
    padding-top: 100%;
    overflow: hidden;
}

.account-home-photo-button {
    background-color: #0776ea;
    background: linear-gradient(90deg, #06d4bd 0%, #0776ea 100%);
    border-style: none;
    border-width: 1px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    line-height: 1em;
    margin-top: 5px;
    position: relative;
    user-select: none;
    font-size: 12px;
    padding: 3px 10px;
}

.account-home-profile-meta {
    /*margin: 20px 0;*/
    list-style: none;
    margin: 0;
    padding: 0;
}

.account-home-profile-meta li {
    margin: 20px 0;
}

@media screen and (max-width: 1024px) {
    .account-home {
        margin: 0 20px;
    }

    .account-home, .account-home-voices main {
        display: block;
    }

    .account-home-player {
        margin: 0 auto;
    }
}