.Button {
    display: inline-block;
    border-style: none;
    border-width: 1px;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    line-height: 1em;
    user-select: none;
}

.Default.Button {
    background-color: #fec536;
    background: linear-gradient(90deg, #fa5a2a 0%, #fec536 100%);
}

.Default.Button, .Primary.Button, .Danger.Button, .ProfileVoices.Button {
    border-style: none;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    padding: 5px 20px;
    text-transform: uppercase;
}

.Primary.Button {
    background-color: #0776ea;
    background: linear-gradient(90deg, #06d4bd 0%, #0776ea 100%);
}

.Disabled.Button {
    font-style: italic;
    opacity: 0.5;
    cursor: not-allowed!important;
}
.Danger.Button {
    background-color: #f00;
}

.Hollow.Button {
    border: 1px solid #fff;
    padding: 2px 5px;
    vertical-align: middle;
}
/*
FA5A2A -> FEC536
06D4BD -> 0776EA
 */
.Link.Button {
    border: none;
    padding: 0;
}
.Link.Button:hover {
    text-decoration: underline;
}
.White.Link.Button, .Plain.White.Button {
    color: #fff;
}

.ProfileVoices.Button {
    background-color: #fff;
    color: #0952b4;
    padding-top: 11px;
    padding-bottom: 11px;
}