.Modal-auth {
    background-color: #fff;
    width: 400px;
}

.Modal-auth-login {
    /*background-color: #fc0;*/
    /*background: linear-gradient(90deg, #fff 0, #fc0 100%);*/
    background-color: #fec536;
    background: linear-gradient(90deg, #fa5a2a 0%, #fec536 100%);
}

.Modal-auth-register {
    background-color: #0776ea;
    background: linear-gradient(90deg, #06d4bd 0%, #0776ea 100%);
}

.Modal-auth h4 {
    border-bottom: 2px solid #000;
    margin: 0;
    padding-bottom: 5px;
}

.Modal-auth-actions {
    text-align: center;
}