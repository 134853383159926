.player-Controls {
    position: relative;
}

.player-Controls-main {
    padding: 20px 0;
}
.player-Controls-primary {
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-Controls-play {
    margin: 0 10px;
}
.player-Controls-select-voice {
    margin-right: 20px;
}

.player-Controls-share {
    margin-left: 20px;
}

.player-Controls-shuffle {
    position: absolute;
    left: 10px;
    top: 10px;
}
.player-Controls-loop {
    position: absolute;
    right: 5px;
    top: 5px;
}

/** Themes **/

.Theme-Dark .player-Controls {
    background-color: #28056b;
}
.Theme-Light .player-Controls {
    background-color: #fff;
}
