.buttons-OptionValueButton {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.buttons-OptionValueButton-options {
    position: absolute;
    top: 100%;
    left: 0;
}

.buttons-OptionValueButton-value {
    cursor: pointer;
}

.buttons-OptionValueButton img {
    width: 14px;
    margin-left: 6px;
}