.player-Player {
    max-width: 300px;
    margin: 0 auto;
}

.player-Player-album {
    text-align: center;
}

.player-Player-name {
    color: #fff;
    font-size: 20px;
    margin: 5px 0 20px;
    text-align: center;
}

.player-NameUnderline {
    background-color: #28056b;
    height: 5px;
    width: 30px;
    margin: 0 auto;
}